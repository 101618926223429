<template>
  <div class="pageWrapper">
    <div class="storageWrapper">
      <div class="storageSectionInfo">
        <div class="storageSectionInfoItem" v-if="storageDataValue">
          <StorageData v-if="storageDataValue" :storageDataValue="storageDataValue" />
        </div>
        <div class="storageSectionInfoItem">
          <StorageInformation v-if="storageDataValue" :storageDataValue="storageDataValue" />
        </div>
      </div>
      <div class="storageSectionItems">
        <DisplayStorage v-if="storageDataValue" :storageDataValue="storageDataValue" :fetchStorageInfo="fetchStorageInfo" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import StorageData from "@/components/storage/StorageData.vue";
import StorageInformation from "@/components/storage/StorageInformation.vue";
import DisplayStorage from "@/components/storage/DisplayStorage.vue";

export default {
  components: {
    StorageData,
    StorageInformation,
    DisplayStorage
    // StorageLocations
  },
  data() {
    return {
      storageDataValue: undefined,
    };
  },
  methods: {
    fetchStorageInfo(searchString) {
      axios.post("storage/getStorage", { search: searchString }).then((response) => {
        this.storageDataValue = response.data;
      })
    },
  },
  created() {
    this.fetchStorageInfo('');
  },
};
</script>

<style scoped lang="scss">
.fa-sync-alt:hover {
  transform: rotate(90deg);
}
.fa-sync-alt {
  transition: .2s;
}
.description {
  padding-left: 10px;
  font-size: 8pt;
  color: rgb(191, 192, 192);
}
.description span {
  background: rgb(226, 132, 132);
  padding:2px 5px;
  margin-left: 5px;
  border-radius: 3px;
  color: white;
  font-size: 8pt;
}

.storageWrapper {
  width: 100%;
  height: auto;
}
.storageSectionInfo {
  width: 100%;
  display: flex;
}
.storageSectionInfo .storageSectionInfoItem {
  width: 50%;
  margin: 10px;
  padding: 10px;
  padding-bottom: 30px;
  border-radius: 10px;
  background: white;
}
.storageSectionItems {
  width: calc(100% - 20px);
  margin: 10px 10px 0 10px;
  background: none;
  border-radius: 10px;
}

@media screen and (max-width: 700px) {
  .storageSectionInfo {
    display: block;
    position: relative;
    width: 100%;
  }

  .storageSectionInfo .storageSectionInfoItem {
    width: calc(100% - 20px);
  }
}
</style>