<template>
  <div class="locationWrapper">
    <div class="tabs">
      <div
        :class="setActive(displayLocations)"
        @click="
          displayProducts = false
          displayLocations = true
        "
        class="tab"
      >
        Storage Locations
      </div>
      <div
        :class="setActive(displayProducts)"
        @click="
          displayProducts = true
          displayLocations = false
        "
        class="tab"
      >
        Products
      </div>
    </div>
    <div v-if="displayLocations" class="display">
      <StorageLocations :storageDataValue="storageDataValue" :fetchStorageInfo="fetchStorageInfo" />
    </div>
    <div v-if="displayProducts" class="display">
      <Products :storageDataValue="storageDataValue" />
    </div>
  </div>
</template>

<script>
import Products from '../storage/displayStorage/Products'
import StorageLocations from '../storage/displayStorage/StorageLocations'

export default {
  props: ['storageDataValue', 'fetchStorageInfo'],
  components: {
    StorageLocations,
    Products,
  },
  computed: {},
  data() {
    return {
      displayProducts: false,
      displayLocations: true,
    }
  },
  mounted() {},
  methods: {
    setActive(data) {
      if (data) {
        return 'active'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.display {
  border-radius: 0px 10px 10px 10px;
  background: white;
  padding: 20px;
}
.tab:hover {
  background: rgba(223, 223, 223, 0.8);
}

.tab {
  background: rgba(223, 223, 223, 0.8);
  color: rgb(139, 139, 139);
  cursor: pointer;
  padding: 10px 35px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-right: 5px;
  font-family: 'AvenirRound';
  font-weight: bold;
  font-size: 10pt;
}
.tabs {
  display: flex;
}
.active {
  color: rgb(54, 54, 54);
  background: white !important;
}
.locationWrapper {
  width: 100%;
  position: relative;
}
</style>