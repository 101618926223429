<template>
  <div>
    <div class="informationCircleWrapper">
      <div class="circleWrapper">
        <div class="circle">
          <p>{{totalProducts}}</p>
        </div>
        <p>Total products</p>
      </div>
      <div class="circleWrapper">
        <div class="circle">
          <p>{{totalStorageLocations}}</p>
        </div>
        <p>Storage locations</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["storageDataValue"],
  data() {
    return {
      totalProducts: 0,
      totalStorageLocations: 0,
    }
  },
  mounted() {
    this.totalStorageLocations = this.storageDataValue.length
    this.storageDataValue.forEach(element => {
      this.totalProducts += element.items.length;
    });
  },
  methods: {

  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
.circleWrapper {
  width: 100%;
  padding: 10px;
}

.circleWrapper p{
  margin-bottom:0;
  font-family: "AvenirRound";
  font-weight: bold;
}

.circle {
  display: flex;
  margin:0px auto 5px auto;
  width:100px;
  border-radius: 50%;
  height: 100px;
  background: rgba(125, 198, 232, 0.8);
  justify-content: center;
  line-height: 100px;
}
.circle p {
  margin-bottom:0;
  color: white;  
  font-family: "AvenirRound";
  font-weight: bold;
  font-size: 20pt;
}
.informationCircleWrapper {
  text-align: center;
  display: flex;
  width: 100%;
  height: auto;
}
</style>