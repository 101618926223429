<template>
  <div class="wrapper">
    <h4>Products</h4>
    <div v-for="(product, i) in productsDataValue" :key="i">
      <div class="product" v-if="product.items.length != 0"> 
        <h4>{{product.productName}}</h4>
        <div class="stores" v-for="(store, j) in product.items" :key="j">
          <p>{{store.amount}} {{store.unit}}</p>
          <p>{{store.name}}</p>
          <p>Batch <span>{{store.batchNumber}}</span></p>
        </div>
      </div>
        <!-- {{product}} -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ['storageDataValue'],
  data() {
    return {
      productsDataValue: undefined
    }
  },
  mounted() {
    this.fetchStorageProductsInfo()
  },
  methods: {
    fetchStorageProductsInfo() {
      axios.post('storage/getProducts').then((response) => {
        this.productsDataValue = response.data
      })
    },
  },
}
</script>

<style scoped lang="scss">
.stores p:nth-child(3):hover {
  color: rgb(182, 45, 45);
}
.stores p:nth-child(3) {
  color: rgb(156, 156, 156);
  border-radius: 3px;
  cursor: pointer;
}

.stores p:nth-child(3) span {
  background: rgb(248, 147, 147);
  padding:1px 5px;
  border-radius: 3px;
  color: white;
  margin-left: 5px;
}

.product h4 {      
  font-family: "AvenirRound";
  font-weight: bold;
  font-size: 13pt; 
  margin-bottom: 0px;
}
.stores p {
  margin-left: 0;
  color: rgb(133, 133, 133);
  font-family: "AvenirRound";
  font-weight: lighter;
  font-size: 10pt; 
  border-radius: 3px;
  margin-right: 5px;
}
.stores {
  display: flex;
}

.wrapper p {
  margin-bottom: 0;
}

.wrapper {
  width: 100%;
  position: relative;
}
</style>