<template>
  <div class="wrapper">
    <div class="top">
      <h4>Storage Locations</h4>
      <div class="search">
        <input type="text" v-model="searchString" placeholder="Search" />
        <i class="fas fa-search"></i>
      </div>
    </div>
    <div v-for="(location, i) in storageDataValue" :key="i">
      <p class="header">{{ location.name }}</p>
      <div v-if="location.items.length == 0">Inga produkter hittades</div>
      <div class="item" v-for="(item, j) in location.items" :key="j">
        <!-- {{item}} -->
        <p>{{ item.productName }}</p>
        <p>{{ item.amount }} {{ item.unit }}</p>
        <p @click="$router.push({ name: 'Batch', params: {batchId: item.batchNumber}})">
          Batch<span>{{ item.batchNumber }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ["storageDataValue", "fetchStorageInfo"],
  data() {
    return {
      searchString: '',
    }
  },
  watch: {
    searchString: function () {
      this.fetchStorageInfo(this.searchString)
    },
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.top .search i {
  cursor: pointer;
  margin-left: 5px;
}
.top .search input:focus {
  border-bottom: 1px solid rgb(86, 165, 255);
  width: 200px;
}
.top .search input {
  font-family: 'AvenirRound';
  font-weight: lighter;
  font-size: 8pt;
  width: 42px;
  transition: 0.2s ease-in-out;
  border: none;
  border-bottom: 1px solid rgb(197, 197, 197);
}

.top .search {
  color: rgb(110, 110, 110);
  margin-left: 10px;
}

.top {
  display: flex;
}

.item p:nth-child(1) {
  background: rgba(125, 198, 232, 0.8);
  cursor: pointer;
  color: white;
  border-radius: 3px;
}
.item p:nth-child(3):hover {
  color: rgb(182, 45, 45);
}
.item p:nth-child(3) {
  color: rgb(156, 156, 156);
  border-radius: 3px;
  cursor: pointer;
}
.item p:nth-child(3) span {
  background: rgb(248, 147, 147);
  padding: 5px;
  border-radius: 3px;
  color: white;
  margin-left: 5px;
}

.item p {
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: 5px;
}
.item {
  display: flex;
}
.header {
  margin-bottom: 0;
  margin: 5px 0;
  font-family: 'AvenirRound';
  font-weight: bold;
  font-size: 12pt;
}
.wrapper h4 {
  font-family: 'AvenirRound';
  font-weight: bold;
  font-size: 15pt;
}
.wrapper {
  width: 100%;
  position: relative;
}
</style>