<template>
  <fragment>
    <div class="layer">
      <div class="titleGroup">
        <p class="title">Connect to Mobile app</p>
      </div>
      <div class="iconGroup">
        <i class="fas fa-sync-alt"></i>
        <i class="fas fa-ellipsis-v"></i>
      </div>
    </div>
    <div class="layer">
      <!-- <p class="description">Recent changes in orders past 24 hours<span>9</span></p> -->
    </div>
    <div class="itemWrapper">
      <QrcodeVue :value="companyIdJsonString"></QrcodeVue>
      <!--  <div class="item" v-for="(item, i) in storageDataValue" :key="i">
      <div :style="randomHSL" class="itemIconBox">
        <p>{{i}}</p>
      </div>
    <div class="itemInfo">
        <h5>{{item.name}} has been updated</h5>
        <div class="timeline">
          <i class="fas fa-box"></i>
          <p>Hummer 10</p>
          <i class="fas fa-arrow-right"></i>
          <p>Bil 1</p>
        </div>
        <div class="itemInfo">
          <h5>{{ item.name }} has been updated</h5>
          <div class="timeline">
            <i class="fas fa-box"></i>
            <p>Hummer 10</p>
            <i class="fas fa-arrow-right"></i>
            <p>Bil 1</p>
          </div>
        </div>
        <div class="editItem">
          <p>Edit</p>
          <i class="fas fa-edit"></i>
      </div>
    </div>-->
    </div>
  </fragment>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  props: ['storageDataValue'],
  components: {
    QrcodeVue,
  },
  data() {
    return {
      companyIdJsonString: '{ "code": "'+this.$store.state.selectedUser.companyId+'" } '
    }
  },
  computed: {
    randomHSL() {
      return { backgroundColor: `hsla(${~~(360 * Math.random())},70%,70%,0.8)` }
    },
  },
}
</script>

<style scoped lang="scss">
.editItem {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 0;
  margin-right: 10px;
  background: rgb(241, 241, 241);
  border-radius: 3px;
  padding: 2px 7px;
}

.editItem p {
  font-family: 'AvenirRound';
  margin: auto 0;
  margin-right: 5px;
  font-size: 8pt;
  font-weight: bold;
}

.editItem i {
  font-size: 8pt;
  margin: auto;
}

.timeline {
  display: inline-flex;
  font-size: 8pt;
}
.timeline i {
  margin: auto;
  color: rgb(147, 192, 147);
}
.timeline p {
  margin-bottom: 0px;
  margin-right: 3px;
  margin-left: 3px;
}
.itemInfo h5 {
  margin: 0;
  font-family: 'AvenirRound';
  font-weight: bold;
  font-size: 12pt;
}
.itemInfo {
  width: 300px;
  margin-left: 10px;
}
.itemIconBox {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: rgb(240, 150, 195);
}
.itemIconBox p {
  line-height: 40px;
  font-size: 18pt;
  font-family: 'AvenirRound';
  color: white;
  font-weight: bold;
  text-align: center;
}
.itemWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.itemWrapper h1 {
  font-family: 'Spartan';
  font-weight: bold;
  margin: 20px;
  margin-top: 40px;
  font-size: 25pt;
  color: #333;
}

.item {
  display: flex;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 20px;
}

.layer {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.layer i {
  float: right;
}

.title {
  font-family: sans-serif;
  font-size: 11pt;
  color: black;
  font-family: 'AvenirRound';
  font-weight: bold;
  padding-top: 10px;
  padding-left: 10px;
  margin-bottom: 0px;
}

.titleGroup {
  width: 50%;
}

.iconGroup {
  padding-top: 10px;
  width: 100%;
  position: relative;
  margin: auto 10px;
}
.iconGroup i {
  margin: 3px;
  cursor: pointer;
}
</style>